import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  IFormRequest,
  ISession,
  ISessionForm,
  ISignatureSubmitter,
  sessionSetting,
} from "../../../type/session";
import { sessionService } from "../../../service/session.service";
import { setResponseValue } from "../api-response";
// import { DefaultSessionForm } from "../../../constants/session";
// import { SessionStatus } from "../../../pages/session/SessionDetails";
import { IBookingDetails } from "../../../type/providerDetails";

export interface ISessionSlice {
  upcomingSession: IBookingDetails[];
  allSession: ISession[];
  currentSession: ISession | null;
  currentSessionForm: ISessionForm | null;
  formRequest: IFormRequest | null;
  sessionSetting: sessionSetting;
}

const initialState: ISessionSlice = {
  upcomingSession: [],
  allSession: [],
  currentSession: null,
  currentSessionForm: null,
  formRequest: null,
  sessionSetting: {
    requireCompletionAtTheBegningOfSession: false,
    requireCompletionAtTheEndOfSession: false,
  },
};

const sessionSlice = createSlice({
  name: "sessionSlice",
  initialState,
  reducers: {
    setCurrentSessionForm: (state, action) => {
      state.currentSessionForm = action.payload;
    },
    updateEndSessionTime: (state, action) => {
      if (state.currentSession) {
        state.currentSession.sessionEndTime = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetMyUpcomingSessions.fulfilled, (state, action) => {
      return {
        ...state,
        upcomingSession: action.payload,
      };
    });
    builder.addCase(createSession.fulfilled, (state, action) => {
      return {
        ...state,
        currentSession: action.payload,
      };
    });
    builder.addCase(GetSessionById.fulfilled, (state, action) => {
      return {
        ...state,
        currentSession: action.payload,
      };
    });
    builder.addCase(GetCompleteSession.fulfilled, (state, action) => {
      return {
        ...state,
        allSession: action.payload,
      };
    });
    builder.addCase(UpdateSession.fulfilled, (state, action) => {
      if (state.currentSession) {
        state.currentSession.status = action.payload?.status;
        state.currentSession.sessionEndTime = action.payload.sessionEndTime;
        state.currentSession.approvalBeginTime =
          action.payload.approvalBeginTime;
        state.currentSession.approvalEndTime = action.payload.approvalEndTime;
      }
    });
    builder.addCase(SendSessionForm.fulfilled, (state, action) => {
      if (state.currentSession) {
        state.currentSession.formRequests = action.payload;
      }
    });
    builder.addCase(GetFormRequestByID.fulfilled, (state, action) => {
      state.formRequest = action.payload;
    });
    builder.addCase(UpdatesSessionFormRequest.fulfilled, (state, action) => {
      if (state.currentSession && state.currentSession.formRequests) {
        state.currentSession.formRequests =
          state.currentSession.formRequests.map((item) => {
            if (item.id === action.payload?.id) {
              return {
                ...item,
                formData: action.payload.formData,
                reviewData: action.payload.reviewData,
                status: action.payload.status,
                pdfUrl: action.payload?.pdfUrl,
                reviewDtm: action.payload?.reviewDtm,
              };
            } else {
              return item;
            }
          });
      }
      if (state.formRequest) {
        state.formRequest.reviewData = action.payload?.reviewData;
        state.formRequest.formData = action.payload?.formData;
        state.formRequest.status = action.payload?.status;
        state.formRequest.submitters = action.payload?.submitters;
      }
    });

    builder.addCase(UpdateReviewComment.fulfilled, (state, action) => {
      if (state.formRequest) {
        state.formRequest.reviewData = {
          ...state.formRequest.reviewData,
          reviewComments: action.payload?.reviewData?.reviewComments,
          checkList: action.payload?.reviewData?.checkList || [], // Ensure it's never undefined
        };
      }
    });
    builder.addCase(GetSessionSetting.fulfilled, (state, action) => {
      state.sessionSetting = action.payload;
    });
    builder.addCase(GetFormByID.fulfilled, (state, action) => {
      state.currentSessionForm = action.payload;
    });
    builder.addCase(SendSignatureInvitation.fulfilled, (state, action) => {
      state.formRequest = action.payload;
    });
  },
});

//get upcoming sessions
export const GetMyUpcomingSessions = createAsyncThunk(
  "/getmySession",
  async ({ id }: { id: string }, { dispatch }) => {
    try {
      const { data } = await sessionService.getUpComingSessions(id);
      return data;
    } catch (e: any) {
      throw dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//creates session
export const createSession = createAsyncThunk(
  "/createSession",
  async (
    { id, sessionType }: { id: string; sessionType: string },
    { dispatch }
  ) => {
    const payloadData = {
      scheduledSessionType: sessionType,
    };
    try {
      const { data } = await sessionService.createSession(id, payloadData);
      return data;
    } catch (e: any) {
      throw dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//get session by Id
export const GetSessionById = createAsyncThunk(
  "/getSessionById",
  async ({ id }: { id: string }, { dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data } = await sessionService.getSessionById(id);
      return data;
    } catch (e: any) {
      throw dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//gets all ongoing and completed sessions
export const GetCompleteSession = createAsyncThunk(
  "/getCompleteSessions",
  async ({ id }: { id: string }, { dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data } = await sessionService.getCompleteSession(id);
      return data;
    } catch (e: any) {
      throw dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//updates session time and status
export const UpdateSession = createAsyncThunk(
  "closeSession",
  async (
    {
      id,
      status,
      sessionEndTime,
      approvalBeginTime,
      approvalEndTime,
    }: {
      id: string;
      status?: string;
      sessionEndTime?: string;
      approvalBeginTime?: string;
      approvalEndTime?: string;
    },
    { dispatch }
  ) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data, message } = await sessionService.updateSession(id, {
        status,
        sessionEndTime,
        approvalBeginTime,
        approvalEndTime,
      });
      if (data) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        dispatch(updateEndSessionTime(sessionEndTime));
        return data;
      }
    } catch (error: any) {
      dispatch(
        setResponseValue({
          name: "message",
          value: error.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//creates form request for session Form
export const SendSessionForm = createAsyncThunk(
  "/sendSessionForm",
  async (
    {
      sessionId,
      formId,
      entityType,
      entityId,
      authId,
      authCode,
      formData,
      isComplete,
      formName,
    }: {
      sessionId: string;
      formId: string;
      entityType: string;
      entityId: string;
      authId: string;
      authCode: string;
      formData?: any;
      isComplete: boolean;
      formName?: string;
    },
    { dispatch }
  ) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const payloadData = {
        sessionId,
        formId,
        entityType,
        entityId,
        authId,
        authCode,
        formData,
        isComplete,
      };
      const { data, message } = await sessionService.sendSessionForm(
        payloadData
      );

      if (data) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));

        // CTO Review: not right logic here!!
        // if (formName === DefaultSessionForm.SIGN_OUT_NOTE) {
        //   dispatch(
        //     UpdateSession({
        //       id: sessionId,
        //       status: SessionStatus.FINALIZE_SESSION_NOTE,
        //     })
        //   );
        // }else if(formName === DefaultSessionForm.SIGN_IN_NOTE){
        //   dispatch(
        //     UpdateSession({
        //       id: sessionId,
        //       status: SessionStatus.SIGN_OUT_PROCEDURE,
        //     })
        //   );
        // }
        return data;
      }
    } catch (error: any) {
      dispatch(
        setResponseValue({
          name: "message",
          value: error.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//updates review data and form data after review
export const UpdatesSessionFormRequest = createAsyncThunk(
  "/updateSessionForm",
  async (
    {
      formId,
      reviewData,
      formData,
      submitters,
    }: {
      formId: string;
      formData: any;
      reviewData?: any;
      submitters?: ISignatureSubmitter[];
    },
    { dispatch }
  ) => {
    const payload = {
      formId,
      formData,
      reviewData,
      submitters,
    };
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data, message } = await sessionService.updateSessionFormRequest(
        formId,
        payload
      );

      if (data) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        return data;
      }
    } catch (error: any) {
      dispatch(
        setResponseValue({
          name: "message",
          value: error.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//updates review comments for session formRequest
export const UpdateReviewComment = createAsyncThunk(
  "/update/reviewComment",
  async (
    {
      formId,
      comment,
    }: {
      formId: string;
      comment: string;
    },
    { dispatch }
  ) => {
    try {
      const { data } = await sessionService.UpdateReviewComment(formId, {
        comment,
      });

      if (data) {
        return data;
      }
    } catch (error: any) {
      dispatch(
        setResponseValue({
          name: "message",
          value: error.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//get form request by Id
export const GetFormRequestByID = createAsyncThunk(
  "/getFormRequestById",
  async (
    {
      id,
    }: {
      id: string;
    },
    { dispatch }
  ) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data } = await sessionService.getFormRequestById(id);

      if (data) {
        return data;
      }
    } catch (error: any) {
      dispatch(
        setResponseValue({
          name: "message",
          value: error.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//get session setting
export const GetSessionSetting = createAsyncThunk(
  "/getSessionSettings",
  async (_, { dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data } = await sessionService.getSessionSettings();
      if (data) {
        return data;
      }
    } catch (error: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: error?.message || "Error occured",
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//get Form by Id (type:session)
export const GetFormByID = createAsyncThunk(
  "/getFormById",
  async (
    {
      id,
    }: {
      id: string;
    },
    { dispatch }
  ) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data } = await sessionService.getFormById(id);
      if (data) {
        return data;
      }
    } catch (error: any) {
      dispatch(
        setResponseValue({
          name: "message",
          value: error.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//updates review data and form data after review
export const SendSignatureInvitation = createAsyncThunk(
  "/signatureInvitation",
  async (
    {
      formId,
      submitter,
    }: {
      formId: string;
      submitter: ISignatureSubmitter;
    },
    { dispatch }
  ) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data, message } = await sessionService.sendSignatureInvitation(
        formId,
        submitter
      );

      if (data) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        return data;
      }
    } catch (error: any) {
      dispatch(
        setResponseValue({
          name: "message",
          value: error.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const { setCurrentSessionForm, updateEndSessionTime } =
  sessionSlice.actions;
export default sessionSlice;
