import { useState } from "react";
import { Box, Card } from "@mui/material";
import MyTasks from "./MyTasks";
import FocusImg from "../../assets/images/images-svg/projectIcon.svg";
import "./task.scss";
import { Close } from "@mui/icons-material";

const Task = () => {
  //handles drawer
  const [drawerOpen, setDrawerOpen] = useState<boolean>(true);

  //#endregion
  return (
    <div className="task-template">
      <Card className={drawerOpen ? "sidePanel-card open" : "sidePanel-card"}>
          <button
            className="sidePanel-card__closeBtn"
            onClick={() => setDrawerOpen(!drawerOpen)}
          >
            <Close color="primary" />
          </button>
        <div className="task-sidePanel">
          <img
            src={FocusImg}
            alt={"Focus"}
            onClick={() => setDrawerOpen(!drawerOpen)}
          />
          Focus
        </div>
        <div className="panel-child activeBtn">My Tasks</div>
      </Card>
      <Box className="task-content">
        <MyTasks drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
      </Box>
    </div>
  );
};

export default Task;
