import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CalendarIcon from "../../assets/images/images-png/CalendarIcon.png";
import ClientImg from "../../assets/images/images-svg/client-large.svg";
import "./session.scss";
import React, { useEffect, useState } from "react";
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
} from "pure-react-carousel";
import { ReactComponent as LeftArrow } from "../../assets/images/images-svg/leftArrow.svg";
import { ReactComponent as RightArrow } from "../../assets/images/images-svg/rightArrow.svg";
import "pure-react-carousel/dist/react-carousel.es.css";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import {
  createSession,
  GetCompleteSession,
  GetMyUpcomingSessions,
  ISessionSlice,
} from "../../redux/features/session/sessionSlice";
import { IEmployeePortalData } from "../../type/providerDetails";
import moment from "moment";
import { SessionStatus, SessionType } from "../../constants/session";
import ProviderImg from "../../assets/images/images-svg/provider-large-vertical.svg";


export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  padding: "8px 20px",
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ECF6FF",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
    height: "40px",
  },
  "&:nth-of-type(even)": {
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
    height: "40px",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  height: "50px",
}));
export const StyledTable = styled(Table)(({ theme }) => ({
  [`& .${tableCellClasses.root}`]: {
    borderBottom: "none",
  },
}));
const Session: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isDesktop, setDesktop] = useState<number>(4); // Number of visible slides
  const [activeIndex, setActiveIndex] = useState<number>(0); // Starting index
  const [isSessionCreated, setIsSessionCreated] = useState<boolean>(false);
  const [filters, setFilters] = useState<{ type: string[]; status: string[] }>({
    type: [],
    status: [],
  });

  //stores session data
  const { upcomingSession, allSession, currentSession } =
    useAppSelector<ISessionSlice>((state) => state.sessionSlice);

  //stores provider details
  const providerDetails = useAppSelector<IEmployeePortalData>(
    (state) => state.providerSlice.providerProfile
  );

  const showArrow = isDesktop < upcomingSession.length;

  // Left scroll button logic
  const handleLeft = () => {
    if (activeIndex > 0) {
      setActiveIndex((prevIndex) => Math.max(prevIndex - isDesktop, 0));
    }
  };

  // Right scroll button logic
  const handleRight = () => {
    if (activeIndex + isDesktop < upcomingSession.length) {
      setActiveIndex((prevIndex) =>
        Math.min(prevIndex + isDesktop, upcomingSession.length - 1)
      );
    }
  };

  //filters all sessions
  const handleFilterChange = () => {
    let filteredSession = allSession.filter((item) => {
      if (filters.status.length && !filters.type.length) {
        return filters.status.includes(item.status);
      } else if (!filters.status.length && filters.type.length) {
        return filters.type.includes(item.sessionType);
      } else if (filters.status.length && filters.type.length) {
        return (
          filters.type.includes(item.sessionType) &&
          filters.status.includes(item.status)
        );
      } else {
        return item;
      }
    });
    return filteredSession;
  };

  // Set the number of slides visible based on screen size (adaptive behavior)
  useEffect(() => {
    // console.log("resize...");
    const updateScreenWidth = () => {
      if (window.innerWidth > 1500) {
        setDesktop(5);
      } else if (window.innerWidth > 1250) {
        setDesktop(4);
      } else if (window.innerWidth > 991) {
        setDesktop(3);
      } else if (window.innerWidth > 650) {
        setDesktop(2);
      } else {
        setDesktop(1);
      }
    };
    updateScreenWidth();
    window.addEventListener("resize", updateScreenWidth);

    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);

  useEffect(() => {
    // console.log("fetch sessions...");
    if (providerDetails.employmentDetails?.id !== "") {
      dispatch(
        GetMyUpcomingSessions({ id: providerDetails.employmentDetails.id })
      );
      dispatch(
        GetCompleteSession({ id: providerDetails.employmentDetails.id })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerDetails.employmentDetails?.id]);

  useEffect(() => {
    // console.log("create sessions...");
    if (currentSession && isSessionCreated) {
      navigate(`/session-details/${currentSession.id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSession, isSessionCreated]);

  // console.log("upcomingSession", upcomingSession);

  return (
    <Box className="session">
      <Typography className="sessionTitle">Coming Sessions</Typography>
      {upcomingSession.length ? (
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={125}
          totalSlides={upcomingSession.length}
          visibleSlides={isDesktop}
          step={isDesktop}
          // currentSlide={activeIndex}
          orientation="horizontal"
          className="cardSlider"
        >
          <Slider className="slider">
            {upcomingSession?.map((item, i) => (
              <Slide index={i} key={i} className="slide">
                <Card className="session-card">
                  <CardContent>
                    <Box className="sessionDate">
                      <span>
                        <img src={CalendarIcon} alt="calendar" />
                      </span>
                      <Typography
                        component="div"
                        fontSize={"16px"}
                        fontWeight={"500"}
                      >
                        {moment(item.specificDate).format("dddd, M/D/YYYY")}
                      </Typography>
                    </Box>
                    <Typography
                      variant="h6"
                      component="div"
                      className="sessionTime"
                    >
                      {`${item.fromTime} - ${item.toTime}`}
                    </Typography>
                    <Box className="clientDetails">
                      {/* {item.sessionType !== SessionType.EmployeeSession ? ( */}
                      <>
                        <span className="userMapCart__img">
                          <img
                            src={
                              item.bookedClientId?.clientProfile?.url ||
                              ClientImg
                            }
                            className="userSmallCircle"
                            alt={item.bookedClientId?.childFirstName}
                          />
                        </span>
                        <Typography className="ClientName">
                          {item?.bookedClientId?.childFirstName}{" "}
                          {item?.bookedClientId?.childLastName} -{" "}
                          {item.authCode}
                        </Typography>
                      </>
                    </Box>
                    <Box className="clientDetails">
                      {/* {item.sessionType !== SessionType.EmployeeSession ? ( */}
                      <>
                        <span className="userMapCart__img">
                          <img
                            src={
                              item.bookedProviderId?.providerProfile?.url ||
                              ProviderImg
                            }
                            className="userSmallCircle"
                            alt={item.bookedProviderId.firstName}
                          />
                        </span>
                        <Typography className="ClientName">
                          {item?.bookedProviderId.firstName}{" "}
                          {item?.bookedProviderId.lastName}
                        </Typography>
                      </>
                    </Box>
                    {/* <Typography className="session-Type">
                      {SessionType.OneOnOne}
                    </Typography> */}
                  </CardContent>
                  <CardActions className="startSession-btn">
                    {item.bookedProviderId.id ===
                    providerDetails.employmentDetails.id ? (
                      <Button
                        size="small"
                        variant="contained"
                        onClick={() => {
                          dispatch(
                            createSession({
                              id: item.id,
                              sessionType: SessionType.OneOnOne,
                            })
                          );
                          setIsSessionCreated(true);
                        }}
                      >
                        Start Session
                      </Button>
                    ) : (
                      <Button size="small" variant="contained">
                        Not Started Yet
                      </Button>
                    )}
                  </CardActions>
                </Card>
              </Slide>
            ))}
          </Slider>
          {showArrow && (
            <ButtonBack className="sliderNavBtn" onClick={handleLeft}>
              <LeftArrow />
            </ButtonBack>
          )}
          {showArrow && (
            <ButtonNext className="sliderNavBtn" onClick={handleRight}>
              <RightArrow />
            </ButtonNext>
          )}
        </CarouselProvider>
      ) : (
        <div className="noSessions">No Upcoming Sessions.</div>
      )}

      <Box className="sessionlist">
        <Typography className="sessionTitle mt-10">All Sessions</Typography>
        <Box className="filters">
          {/* Session Type Dropdown */}
          <FormControl sx={{ m: 1, width: 250 }} size="small">
            <InputLabel id="session-type-label">Type</InputLabel>
            <Select
              labelId="session-type-label"
              id="session-type-select"
              value={filters.type}
              multiple
              label="Type"
              onChange={(e) =>
                setFilters({ ...filters, type: e.target.value as string[] })
              }
              renderValue={(selected) => selected.join(", ")}
            >
              {Object.values(SessionType).map((type) => (
                <MenuItem key={type} value={type}>
                  <Checkbox checked={filters.type.includes(type)} />
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Session Status Dropdown */}
          <FormControl sx={{ m: 1, width: 250 }} size="small">
            <InputLabel id="session-status-label">Status</InputLabel>
            <Select
              labelId="session-status-label"
              id="session-status-select"
              multiple
              value={filters.status}
              label="Status"
              onChange={(e) =>
                setFilters({ ...filters, status: e.target.value as string[] })
              }
              renderValue={(selected) => selected.join(", ")}
            >
              {Object.values(SessionStatus).map((status) => (
                <MenuItem key={status} value={status}>
                  <Checkbox checked={filters.status.includes(status)} />
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>

      <TableContainer component={Paper} className="tableResponsive">
        <StyledTable
          sx={{
            minWidth: 700,
            borderCollapse: "separate",
            cursor: "pointer",
            borderSpacing: "0 8px",
          }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>Client</StyledTableCell>
              <StyledTableCell>Provider</StyledTableCell>
              <StyledTableCell>Activity</StyledTableCell>
              {/* <StyledTableCell>#People</StyledTableCell> */}
              <StyledTableCell>Schedule</StyledTableCell>
              <StyledTableCell>Render</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Update Time</StyledTableCell>

            </TableRow>
          </TableHead>
          {allSession.length ? (
            <TableBody>
              {handleFilterChange()?.map((item, index) => (
                <StyledTableRow
                  key={index}
                  onClick={() => {
                    navigate(`/session-details/${item.id}`);
                  }}
                >
                    <StyledTableCell>
                    {item.serviceSchedule?.bookedClientId?.childFirstName}{" "}
                    {item.serviceSchedule?.bookedClientId?.childLastName}
                  </StyledTableCell>
                  <StyledTableCell>
                    {item.serviceSchedule?.bookedProviderId?.firstName}{" "}
                    {item.serviceSchedule?.bookedProviderId?.lastName}
                  </StyledTableCell>
                  <StyledTableCell>
                    {item.serviceSchedule?.serviceType}
                  </StyledTableCell>
                
                  {/* <StyledTableCell>1</StyledTableCell> */}
                  <StyledTableCell>
                    <Typography>
                      {moment(item.serviceSchedule?.specificDate).format(
                        "dddd, M/D/YYYY"
                      )}
                    </Typography>
                    <Typography>
                      {item.serviceSchedule?.fromTime} -{" "}
                      {item.serviceSchedule?.toTime}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography>
                      {moment(item.sessionBeginDtm).format("dddd, M/D/YYYY")}
                    </Typography>
                    {item.sessionEndTime ? (
                      <Typography>
                        {item.approvalBeginTime} - {item.approvalEndTime}
                      </Typography>
                    ) : (
                      <Typography>{item.sessionBeginTime} - ongoing</Typography>
                    )}
                  </StyledTableCell>
                  <StyledTableCell>{item.status}</StyledTableCell>
                  <StyledTableCell>
                    {moment.utc(item.updatedAt).format("MM/DD/YYYY hh:mm:ssa")}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <StyledTableCell colSpan={12}>
                  <div className="noSession-block">No session Found.</div>
                </StyledTableCell>
              </TableRow>
            </TableBody>
          )}
        </StyledTable>
      </TableContainer>
    </Box>
  );
};

export default Session;
