import axios from "../axios/axios";
import { FileCategory } from "../type/providerDetails";

const getProviderDetails = async () => {
  try {
    const data = await axios.get(`/employee`);
    return data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

const getAllBoardingStages = async () => {
  try {
    const data = await axios.get(`/employee/boardStage`);
    return data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

//get booking details
export const getProviderBookingDetails = async () => {
  try {
    const data = await axios.get(`/employee/schedule`);
    return data;
  } catch (error: any) {
    throw error?.response?.data
  }
}

// post File api
const postproviderFiles = async (
  category: string,
  type: string,
  model: string,
  modelId: string,
  files: any[]
) => {
  if (!Object.values(FileCategory).includes(category as FileCategory)) {
    throw new Error("Invalid file category");
  }

  const formData = new FormData();
  formData.append("category", category);
  formData.append("type", type);
  formData.append("model", model);
  formData.append("modelId", modelId);
  formData.append("entityId", modelId);
  files.forEach((file) => {
    if (!file.fileKey) {
      formData.append("file", file);
    }
  });
  try {
    const { data } = await axios.post("/file/upload-files", formData);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

const getProvidersByEmployee = async () => {
  try {
    const data = await axios.get(`/employee/provider`);
    return data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

// get Provider Files by id 
export async function getProviderFilesById(entityId: string) {
  try {
    const { data } = await axios.get(`/files?entityId=${entityId}`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
}

export async function updateAvailabilityDetails(
  availabilities: any[]
) {
  try {
    let data = null;
    data = await axios.post(`/availability`, {
      availabilities: availabilities,
    });
    return { status: data.data.status, message: data.data.message };
  } catch (e: any) {
    throw e?.response?.data;
  }
}

//delete availability by id
export async function deleteAvailabilityById(id: string) {
  try {
    const { data } = await axios.delete(`/availability/${id}`);

    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
}

//delete file by id
export async function deleteFileById(id: string) {
  try {
    const { data } = await axios.delete(`/file?fileId=${id}`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
}

//get employee-portal settings
const getEmployeeSetting = async () => {
  try {
    const { data } = await axios.get(`/employeeSetting`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export async function getProviderOnBoardingStages() {
  try {
    const { data } = await axios.get("/board/onboarding");
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
}



const providerService = {
  getProviderDetails,
  getAllBoardingStages,
  getProviderBookingDetails,
  getEmployeeSetting,
  postproviderFiles,
  getProvidersByEmployee,
  getProviderFilesById,
  updateAvailabilityDetails,
  deleteAvailabilityById,
  deleteFileById,
  getProviderOnBoardingStages
};

export default providerService;
