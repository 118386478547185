import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import "./message-details.scss";
import { IEmployeePortalData } from "../../../type/providerDetails";
import { RootState, useAppDispatch } from "../../../redux/store";
import { useSelector } from "react-redux";
import { GetProviderDetails } from "../../../redux/features/providerSlice";
import { PostCreateMessage } from "../../../redux/features/message/message-slice";
import { MuiFileInput } from "mui-file-input";
import BackupIcon from "@mui/icons-material/Backup";

interface IComposeMessageForm {
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  selectedUser: {
    id: string;
    name: string;
    type: "Provider" | "Client";
  };
}

const ComposeMessage: React.FC<IComposeMessageForm> = ({
  setOpenDialog,
  selectedUser,
}) => {
  const dispatch = useAppDispatch();

  //#region variables region
  //used to store provider details from redux
  const providerDetails = useSelector<RootState, IEmployeePortalData>(
    (state) => state.providerSlice.providerProfile
  );

  //stores sender name
  let senderName = `${providerDetails.employmentDetails.firstName}${" "}${
    providerDetails.employmentDetails.lastName
  }`;

  //used to store from name
  const [from, setFrom] = useState<string>(senderName);

  //used to subject name
  const [subjects, setSubject] = useState<string>("");

  //used to store message body
  const [textfields, setTextField] = useState<string>("");

  //handle errors
  const [haserror, setHasError] = useState<boolean>(false);

  //file error Message
  const [errorMessage, setErrorMessage] = useState<string>();

  //stores file attachments data
  const [newFiles, setNewFiles] = useState<any[]>([]);

  //#endregion

  const handleUploadFile = (files: File[]) => {
    setErrorMessage(undefined);
    const totalFiles = newFiles.length + files.length;
    const MAX_FILE_SIZE = 5 * 1024 * 1024; //
    const validFiles = files.filter((file) => file.size <= MAX_FILE_SIZE);
    const invalidFiles = files.filter((file) => file.size > MAX_FILE_SIZE);
    if (invalidFiles.length > 0) {
      setErrorMessage("File size exceeds the limit of 5 MB.");
    }
    if (totalFiles <= 5) {
      setNewFiles([...newFiles, ...validFiles]);
    } else {
      setErrorMessage("You can only upload up to 5 files.");
    }
  };

  const postMessage = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (!from.trim() || !textfields.trim() || !subjects.trim()) {
      setHasError(true);
      return;
    }
    dispatch(
      PostCreateMessage({
        entityId: selectedUser.id,
        subject: subjects,
        message: textfields,
        whoCanView: ["admin", "parent", "employee"],
        type: "external",
        from: senderName,
        entityType: selectedUser.type,
        files: newFiles ? newFiles : [],
        messagetype: "externalCount",
      })
    );
    setOpenDialog(false);
  };

  //#region useEffect region

  useEffect(() => {
    dispatch(GetProviderDetails());
  }, [dispatch]);

  //#endregion
  console.log(errorMessage, haserror, "errorMessage");

  return (
    <>
      <div className="stepperForm__inner p-30px message-templateBclobk">
        <Grid container spacing={3}>
          <Grid item xs={2} md={2} display={"flex"}>
            <div className="fromGroup-chips">
              <InputLabel className="fromLabel">Send To</InputLabel>
            </div>
          </Grid>
          <Grid item xs={10} md={10}>
            <div className="fromGroup-chips">
              <Card className="fromGroup-chip-active">
                {selectedUser?.name}
              </Card>
            </div>
          </Grid>
          <Grid item xs={2} md={2} display={"flex"}>
            <div className="fromGroup-chips">
              <InputLabel className="fromLabel">Sender Name</InputLabel>
            </div>
          </Grid>
          <Grid item xs={10} md={10}>
            <Box className="fromGroup">
              <TextField
                className="w-100"
                id="outlined-basic"
                label=""
                variant="outlined"
                placeholder="Enter from name"
                name="SENDER"
                value={from}
                autoFocus
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                  setFrom(e.target.value);
                }}
              />
            </Box>
            {haserror && !senderName.trimStart() && (
              <FormHelperText style={{ color: "#d32f2f" }}>
                Please enter sender's name
              </FormHelperText>
            )}
          </Grid>

          <Grid item xs={2} md={2} display={"flex"}>
            <Box
              className="fromGroup-chips"
              display={"flex"}
              alignItems={"center"}
            >
              <InputLabel className="fromLabel">Subject</InputLabel>
            </Box>
          </Grid>
          <Grid item xs={10} md={10}>
            <Box className="fromGroup">
              <TextField
                className="w-100"
                id="outlined-basic"
                label=""
                variant="outlined"
                placeholder="Enter message subject"
                value={subjects}
                onChange={(e) => {
                  setSubject(e.target.value);
                }}
              />
            </Box>
            {haserror && !subjects.trimStart() && (
              <FormHelperText style={{ color: "#d32f2f" }}>
                Please enter subject
              </FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={12}>
            <Box className="commentForm">
              <TextareaAutosize
                value={textfields}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                  setTextField(e.target.value);
                }}
                placeholder="Leave your in-mail message here... "
                minRows={5}
                aria-label="minimum height"
              />
            </Box>
            {haserror && !textfields.trimStart() && (
              <FormHelperText style={{ color: "#d32f2f" }}>
                Please enter message
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} md={12}>
            Attachments (up to 5, max size 5MB):
            <Box className="fileUploadBlock">
              {/* <FileUpload
                maxFiles={5}
                maxSize={5000000}
                multiple
                value={newFiles}
                onChange={(e: File[]) => {
                  setHasError(false);
                  if (newFiles.length + e.length <= 5) {
                    setHasError(false);
                    setNewFiles([...newFiles, ...e]);
                  } else {
                    setHasError(true);
                  }
                }}
              /> */}
              {/* <Box className="fileUploadBlock"> */}
              <Box className="uploadIconBox">
                <BackupIcon color="primary" className="uploadIcon" />
                <Typography>
                  Drag 'n' drop files here or click to add files
                </Typography>
              </Box>

              <MuiFileInput
                multiple
                hideSizeText
                sx={{
                  "& .css-1fel157 > span": { display: "none" },
                  "& .css-flami6": { display: "none" },
                  "& fieldset": { border: "none" },
                }}
                value={newFiles}
                onClick={() => setErrorMessage(undefined)}
                onChange={(e: File[]) => handleUploadFile(e)}
              />
              {/* </Box> */}
            </Box>
            {errorMessage !== undefined && (
              <FormHelperText style={{ color: "#d32f2f" }}>
                {errorMessage}
              </FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <div className="uploadProfile__img selectedIn-fileBox">
              {newFiles &&
                newFiles.map((file, index) => {
                  return (
                    <div className="selectedIn-file">
                      <h1>{file.name}</h1>
                      <span>
                        <DeleteIcon
                          onClick={() => {
                            setNewFiles(
                              newFiles.filter(
                                (_, fileIndex) => fileIndex !== index
                              )
                            );
                          }}
                        />
                      </span>
                    </div>
                  );
                })}
            </div>
          </Grid>
        </Grid>
      </div>
      <Box className="stepperForm__footer b-0">
        <Box sx={{ flex: "1 1 auto" }} />
        <Button onClick={postMessage} className="button">
          Send Now
        </Button>
      </Box>
    </>
  );
};

export default ComposeMessage;
