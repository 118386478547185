import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Popover,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";
import {
  BorderColor,
  CalendarTodayOutlined,
  South,
  North,
} from "@mui/icons-material";
import moment from "moment";

import { useAppDispatch, useAppSelector } from "../../redux/store";
import {
  GetAllTaskType,
  GetMyTask,
  PatchTaskById,
} from "../../redux/features/taskManager/taskManagerSlice";

import EditTaskModal from "./EditTaskModal";

import { FilterData, ITask, Priority, Status } from "../../type/taskManager";

import { dateToFromNowDaily } from "../../helper/reverselist";

import ProviderImg from "../../assets/images/images-svg/provider-large-vertical.svg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "./myTask.scss";

// Custom Table
const StyledTable = styled(Table)(() => ({
  [`& .${tableCellClasses.root}`]: {
    borderBottom: "none",
  },
}));

// Custom Table Col style
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  display: "flex",
  height: "100%",
  padding: "5px 20px",
  flex: 2,
  alignItems: "center",
  textAlign: "left",
}));

// Custom Table Row style
const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ECF6FF",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
    height: "60px",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  height: "60px",
  borderRadius: "20px",
}));

interface IFilterData {
  [key: string]: string;
  DateRange: string;
  Status: string;
  Priority: string;
}

interface TaskHeaderProps {
  drawerOpen: boolean;
  setDrawerOpen: Dispatch<SetStateAction<boolean>>;
  filterData: IFilterData;
  setFilterData: Dispatch<SetStateAction<IFilterData>>;
  isSort: { date: boolean; priority: boolean };
  setIsSort: Dispatch<
    SetStateAction<{
      date: boolean;
      priority: boolean;
    }>
  >;
}
const TaskHeader: React.FC<TaskHeaderProps> = ({
  filterData,
  setFilterData,
  isSort,
  setIsSort,
  drawerOpen,
  setDrawerOpen,
}) => {
  return (
    <div className="taskHeader">
      <div className="drawer-handler">
        <Button
          onClick={() => setDrawerOpen(!drawerOpen)}
          sx={{ minWidth: "25px" }}
        >
          {drawerOpen ? (
            <ArrowBackIosIcon color="primary" />
          ) : (
            <ArrowForwardIosIcon color="primary" />
          )}
        </Button>
        <Typography>Filter:</Typography>
      </div>

      <div className="taskFormController">
        {FilterData.map(({ data, label }, index) => (
          <div className="taskFilter">
            <FormControl key={label} className="task-outer">
              <InputLabel id="demo-simple-select-label">{label}</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filterData[label]}
                style={{ width: "200px", height: "30px" }}
                onChange={(e) =>
                  setFilterData({ ...filterData, [label]: e.target.value })
                }
              >
                {data.map((item, index) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <>
              {label === "DateRange" && (
                <div
                  onClick={() => setIsSort({ ...isSort, date: !isSort.date })}
                  style={{ color: "#939393", marginLeft: "-12px" }}
                >
                  {isSort.date ? <North /> : <South />}
                </div>
              )}
              {label === "Priority" && (
                <div
                  onClick={() =>
                    setIsSort({ ...isSort, priority: !isSort.priority })
                  }
                  style={{ color: "#939393", marginLeft: "-12px" }}
                >
                  {isSort.priority ? <North /> : <South />}
                </div>
              )}
            </>
          </div>
        ))}
      </div>
    </div>
  );
};
const MyTasks: React.FC<{
  drawerOpen: boolean;
  setDrawerOpen: Dispatch<SetStateAction<boolean>>;
}> = ({ drawerOpen, setDrawerOpen }) => {
  const dispatch = useAppDispatch();
  const [statusAnchor, setStatusAnchor] = useState<HTMLButtonElement | null>(
    null
  );
  let openStatusAnchor = Boolean(statusAnchor);
  const [priorityAnchor, setPriorityAnchor] =
    useState<HTMLButtonElement | null>(null);
  let openPriorityAnchor = Boolean(priorityAnchor);
  const id =
    openStatusAnchor || openPriorityAnchor ? "simple-popover" : undefined;
  const [currentTask, setCurrentTask] = useState<ITask>();
  const [editTaskModal, setEditTaskModal] = useState<boolean>(false);
  const [filterData, setFilterData] = useState<IFilterData>({
    DateRange: "",
    Priority: "",
    Status: "",
  });
  const [isSortData, setIsSortData] = useState<{
    date: boolean;
    priority: boolean;
  }>({
    date: false,
    priority: false,
  });

  const tasks = useAppSelector((state) => state.taskManagerSlice.tasks);

  const filterTaskData = (data: ITask[]) => {
    //checking for filter criteria
    const isPriority =
      filterData.Priority !== "" && filterData.Priority !== "All";
    const isStatus = filterData.Status !== "" && filterData.Status !== "All";
    const isDate =
      filterData.DateRange !== "" && filterData.DateRange !== "All";

    //task filter
    if (isPriority || isStatus || isDate) {
      const result = data.filter((task) => {
        //checks for every single taslk
        const satisfiesPriority =
          !isPriority || task.priority === filterData.Priority;
        const satisfiesStatus = !isStatus || task.status === filterData.Status;
        const satifiesDate =
          !isDate || filterDataByTimeType(task, filterData.DateRange);

        //if satisfies all three conditions then return true
        return satisfiesPriority && satisfiesStatus && satifiesDate;
      });
      return result;
    } else {
      //return as it is
      return data;
    }
  };

  //filter for timeType
  const filterDataByTimeType = (task: ITask, timeType: string) => {
    const currentDate = new Date(); // Get current date

    switch (timeType) {
      //case 1
      case "Current Month":
        const currentMonthStart = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        );
        return new Date(task?.createdAt) >= currentMonthStart;

      //case 2
      case "Last 3 Months":
        const threeMonthsAgo = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 3,
          currentDate.getDate()
        );
        return new Date(task?.createdAt) >= threeMonthsAgo;

      //case 3
      case "Last Year":
        const lastYearStart = new Date(
          currentDate.getFullYear() - 1,
          currentDate.getMonth(),
          currentDate.getDate()
        );
        return new Date(task?.createdAt) >= lastYearStart;

      default:
        return task;
    }
  };

  // sort task Data
  const sortTaskData = (item: ITask[]) => {
    let data: ITask[] = [];
    if (item?.length > 0) {
      let result = item.slice().sort((a: ITask, b: ITask) => {
        const priorityOrder = ["High", "Medium", "Low"];

        //if date and priority sort both is true
        if (isSortData.date && isSortData.priority) {
          return (
            (new Date(a.createdAt) as any) - (new Date(b.createdAt) as any) &&
            priorityOrder.indexOf(a.priority) -
              priorityOrder.indexOf(b.priority)
          );
        }
        //if only date sorting is true
        else if (isSortData.date) {
          return (
            (new Date(a.createdAt) as any) - (new Date(b.createdAt) as any)
          );
        }
        //if only priority sorting is true
        else if (isSortData.priority) {
          return (
            priorityOrder.indexOf(a.priority) -
            priorityOrder.indexOf(b.priority)
          );
        }
        //else just sort in descending order with createdAt property
        else {
          return 0;
        }
      });
      data = [...result];
      return data;
    }

    return item;
  };
  //updates status of task
  const handleUpdate = (type: string, value: string) => {
    if (currentTask) {
      dispatch(
        PatchTaskById({
          type: type, //"status",
          value: value,
          id: currentTask.id,
        })
      );
    }
    setStatusAnchor(null);
    setPriorityAnchor(null);
  };

  useEffect(() => {
    dispatch(GetMyTask());
    dispatch(GetAllTaskType());
  }, []);

  return (
    <div>
      <TaskHeader
        filterData={filterData}
        setFilterData={setFilterData}
        isSort={isSortData}
        setIsSort={setIsSortData}
        setDrawerOpen={setDrawerOpen}
        drawerOpen={drawerOpen}
      />
      <Grid item xs={12} className="listingTable">
        <TableContainer component={Paper} className="tableResponsive">
          <StyledTable
            sx={{
              minWidth: "1350px",
              borderCollapse: "separate",
              cursor: "pointer",
            }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <StyledTableCell sx={{ flex: 2 }}>Assign To</StyledTableCell>
                <StyledTableCell sx={{ flex: 1.5 }}>Task Name</StyledTableCell>
                <StyledTableCell sx={{ flex: 2, width: "150px" }}>
                  Task Type
                </StyledTableCell>
                <StyledTableCell sx={{ flex: 2 }}>Due Date</StyledTableCell>
                <StyledTableCell sx={{ flex: 1 }}>Priority</StyledTableCell>
                <StyledTableCell sx={{ flex: 1 }}>Status</StyledTableCell>
                <StyledTableCell sx={{ flex: 1 }}>Complete</StyledTableCell>
                <StyledTableCell sx={{ flex: 2 }}>Updated On</StyledTableCell>
                <StyledTableCell sx={{ flex: 1 }}>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortTaskData(filterTaskData(tasks))?.map((task, index) => (
                <StyledTableRow
                  key={task.id}
                  style={{
                    display: "flex",
                    // justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <StyledTableCell>
                    {/* <div className="image-outer">
                      <img
                        className="userSmallCircle"
                        src={task.assignedTo?.profilePictureUrl || ProviderImg}
                        alt=""
                      />
                    </div> */}
                    <div>
                      <Typography style={{ fontWeight: 400, fontSize: "16px" }}>
                        {task?.assignedTo !== null
                          ? task?.assignedTo.firstName +
                            " " +
                            task?.assignedTo.lastName
                          : ""}
                      </Typography>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell sx={{ flex: 1.5 }}>
                    <Typography className="taskDetails_main">
                      {task?.name}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography className="taskDetails_main">
                      {task?.type}
                    </Typography>
                  </StyledTableCell>

                  <StyledTableCell>
                    <div className="calender-outer">
                      {/* <input
                        min={
                          task.dueDate
                            ? moment.utc(task.dueDate).format("MM/DD/YYYY")
                            : new Date().toLocaleString()
                        }
                        style={{ position: "absolute", opacity: "0" }}
                        type="date"
                        onChange={(e) => {
                          handleUpdate(
                            "dueDate",
                            moment.utc(e.target.value).format("MM/DD/YYYY")
                          ); //store UTC date
                          setCurrentTask(task);
                        }}
                      /> */}
                      <CalendarTodayOutlined />
                      {task.dueDate ? moment(task.dueDate).format("LL") : ""}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell sx={{ flex: 1 }}>
                    <div
                      className={`task_priority_${task.priority}`}
                      onClick={({ currentTarget }: any) => {
                        setPriorityAnchor(currentTarget);
                        setCurrentTask(task);
                      }}
                    >
                      {task?.priority}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell sx={{ flex: 1 }}>
                    <div
                      className={`task-status-${task.status.replace(" ", "")}`}
                      onClick={({ currentTarget }: any) => {
                        setStatusAnchor(currentTarget);
                        setCurrentTask(task);
                      }}
                    >
                      {task?.status}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell sx={{ flex: 1 }}>
                    <div
                      className="taskDetails_main"
                      // className={`task-status-${task.status.replace(" ", "")}`}
                    >
                      {
                        task?.checklist?.filter((item) => item.checked === true)
                          .length
                      }{" "}
                      / {task?.checklist?.length}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div className="taskDetails_main">
                      {dateToFromNowDaily(moment.utc(task?.updatedAt).toDate())}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell sx={{ flex: 1 }}>
                    <div style={{ display: "flex", gap: "15px" }}>
                      <BorderColor
                        sx={{ cursor: "pointer" }}
                        style={{ color: "#096DD9" }}
                        onClick={() => {
                          setEditTaskModal(true);
                          setCurrentTask(task);
                        }}
                      />
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </StyledTable>
        </TableContainer>
        <Popover
          id={id + "priority"}
          open={openPriorityAnchor}
          onClose={() => setPriorityAnchor(null)}
          anchorEl={priorityAnchor}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <div style={{ width: "138px", padding: "10px" }}>
            {Object.values(Priority).map((item, i) => {
              return (
                <MenuItem
                  key={i}
                  className={`task_priority_${item} mb-10`}
                  // className={`task-status-${item.replace(" ", "")} mb-10`}
                  value={item}
                  onClick={() => handleUpdate("priority", item)}
                >
                  {item}
                </MenuItem>
              );
            })}
          </div>
        </Popover>
        <Popover
          id={id + "status"}
          open={openStatusAnchor}
          onClose={() => setStatusAnchor(null)}
          anchorEl={statusAnchor}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <div style={{ width: "138px", padding: "10px" }}>
            {Object.values(Status).map((item, i) => (
              <MenuItem
                key={i}
                className={`task-status-${item.replace(" ", "")} mb-10`}
                value={item}
                onClick={() => handleUpdate("status", item)}
              >
                {item}
              </MenuItem>
            ))}
          </div>
        </Popover>
      </Grid>
      {editTaskModal && currentTask && (
        <Dialog open={editTaskModal} className="boardLargeModel">
          <EditTaskModal
            setAddTaskModal={setEditTaskModal}
            currentTask={currentTask}
          />
        </Dialog>
      )}
    </div>
  );
};

export default MyTasks;
